import React from "react"
import BusinessSendMoney from "../../../components/body/pages/en-ng/business/bill-payments/sendMoney"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const SendMoneyPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/send-money"}
      title="Money Transfers for Businesses: Single and Bulk Transfers | Kuda for Business"
      description="Send funds effortlessly with single & bulk transfers, ensuring smooth transactions & efficient financial operations. Explore Kuda's business solutions now!"
    />
    <BusinessSendMoney />
  </Layout>
)

export default SendMoneyPage
